<template>
  <div>
    <div class="section">Raffle controller</div>

    {{ isHost }}
    <div v-if="isHost" class="buttons-container">
      <button @click="() => triggerButton('onTrigger')">Sorteo</button>
      <button @click="() => triggerButton('onRaffleReset')">
        Resetear Sorteo
      </button>
      <button @click="() => triggerButton('onDisableRaffle')">
        Deshabilitar sorteo
      </button>
    </div>

    <div v-else>
      <div v-if="isPreviousWinner">
        🏆 Ya ganaste! No puedes participar del próximo sorteo
      </div>

      <div v-else-if="isQueued">
        Hay un sorteo en curso. Debes esperar a que se inicie uno nuevo
      </div>

      <div v-else class="currentStatus">
        <p>Current Status: --=== >> {{ this.currentStatus }}</p>

        <p v-if="this.currentStatus === RAFFLE_STATES.NOT_READY">
          El sorteo aun no está habilitado. Volvé más tarde
        </p>
        <p v-if="this.currentStatus === RAFFLE_STATES.WAITING_FOR_PLAYERS">
          Esperando participantes
        </p>
        <p v-else-if="this.currentStatus === RAFFLE_STATES.RUNNING">
          Sorteando... tic tac
        </p>
        <p v-else-if="this.currentStatus === RAFFLE_STATES.COMPLETED">
          Tenemos un ganador
        </p>

        <div v-if="this.currentStatus === RAFFLE_STATES.COMPLETED">
          <p v-if="this.winner" class="raffleWinner">Ganaste !</p>
          <p v-else class="raffleLoser">Seguí participando !</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RAFFLE_STATES } from "../constants";
import "./RaffleController.scss";
import axios from "axios";

export default {
  components: {},
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    if (!this.isHost) {
      await this.getWinners(); //TODO: sumar un loading mientras se carga el estado
    }
  },
  computed: {
    isHost() {
      return this.$store.getters["user/hasAdminAuthority"];
    },
  },
  data() {
    return {
      RAFFLE_STATES,
      winner: false,
      currentStatus: null,
      isPreviousWinner: null,
      isQueued: false,
      isTourLeader: null,
    };
  },
  watch: {
    async currentStatus(newStatus, oldStatus) {
      if (
        oldStatus === RAFFLE_STATES.COMPLETED &&
        newStatus === RAFFLE_STATES.WAITING_FOR_PLAYERS
      ) {
        // Si se reseteo el sorteo
        await this.getWinners();
        this.isQueued = false;
      }
      if (!oldStatus) {
        if (
          newStatus === RAFFLE_STATES.RUNNING ||
          newStatus === RAFFLE_STATES.COMPLETED
        ) {
          //Si ingreso mientras el sorteo estaba en funcionamiento
          this.isQueued = true;
        }
      }
    },
    immediate: true,
  },
  methods: {
    async getWinners() {
      await axios
        .get(process.env.VUE_APP_API_BASE + "/api/rafflewinners/?limit=999")
        .then((res) => {
          const previousWinners = res.data.docs;
          this.isPreviousWinner = previousWinners.some(
            (winner) => winner.user.id === this.$store.state.user.profile.id
          );
        })
        .catch((err) => {
          throw err;
        });
    },
    triggerButton(msg) {
      this.$socket.client.emit("gameMessage", {
        type: msg,
      });
    },
  },

  sockets: {
    winner(currentWinner) {
      if (currentWinner.winnerId === this.$store.state.user.profile.id) {
        this.winner = true;
      } else {
        this.winner = false;
      }
    },
    currentStatus(status) {
      this.currentStatus = status.currentStatus;
    },
  },
};
</script>
